.contact-page {
  color: #333;
}

.contact-hero {
  background: linear-gradient(135deg, #1868fd 0%, #2a2a72 100%);
  color: white;
  padding: 4rem 0;
  min-height: 40vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contact-card {
  background-color: white;
  border-radius: 10px;
  overflow: hidden;
}

.contact-card h2 {
  color: #1868fd;
  font-weight: bold;
}

.field {
  background-color: #f8f9fa !important;
  border: 1px solid #e9ecef;
  border-radius: 5px;
  padding: 10px;
  transition: all 0.3s ease;
}

.field:focus {
  border-color: #1868fd;
  box-shadow: 0 0 0 0.2rem rgba(24, 104, 253, 0.25);
}

.field::placeholder {
  color: #6c757d;
  font-size: 0.9rem;
}

.submit-btn {
  background-color: #1868fd;
  color: white;
  border: none;
  border-radius: 25px;
  padding: 10px 20px;
  font-weight: bold;
  transition: all 0.3s ease;
}

.submit-btn:hover {
  background-color: #2a2a72;
  transform: translateY(-2px);
}

.contact-icon {
  font-size: 2.5rem;
  color: #1868fd;
  margin-bottom: 1rem;
}

.contact-info h3 {
  color: #1868fd;
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
}

.contact-info p {
  color: #6c757d;
}

.map-section {
  height: 450px;
}

@media (max-width: 768px) {
  .contact-hero {
      min-height: 30vh;
  }
}
