.price-list-container {
    padding: 2rem 0;
  }
  
  .table {
    background-color: #f8f9fa;
  }
  
  .table thead th {
    background-color: #007bff;
    color: white;
  }
  
  .table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 123, 255, 0.05);
  }
  
  .table-hover tbody tr:hover {
    background-color: rgba(0, 123, 255, 0.1);
  }
  