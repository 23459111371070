.gallery-page {
  background-color: #f8f9fa;
}

.gallery-card {
  transition: transform 0.3s ease-in-out;
  cursor: pointer;
}

.gallery-card:hover {
  transform: scale(1.05);
}

.gallery-card .card-img-top {
  height: 200px;
  object-fit: cover;
}

.gallery-card .card-body {
  background-color: #fff;
}

.gallery-card .card-title {
  font-size: 1rem;
  font-weight: 600;
  text-align: center;
}

@media (max-width: 576px) {
  .gallery-card .card-img-top {
      height: 150px;
  }
}
