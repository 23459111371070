*{
    padding: 0;
    margin: 0;
    font-family: 'Poppins', sans-serif;
}
.nav-link {
    color: #000;
    font-weight: 500;
    transition: color 0.3s ease, transform 0.3s ease;
}

.nav-link:hover {
    color: #D7AF4D;
    transform: translateY(-2px);
}
.nav-link.active {
    color: #D7AF4D!important;
    position: relative;
}

/* .nav-link.active::after {
    content: '';
    position: absolute;
    bottom: -5px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #D7AF4D;
} */

.newNav {
    background-color: #f8f9fa;
}


@media (max-width:991px) {
    .nav-pad{
        padding: 1rem;
    }
    .nav-link{
        padding:1rem;
    }
}
.nav-sm{
    box-shadow: 0 4px 2px -2px gray;
}

#logo{
    width: 150px !important;
    border-radius: 10px;
}


.logo-name h1{
    font-family: 'Belleza', sans-serif;
    font-size: 40px;
    font-weight: 500;
    color: #D7AF4D;
}
.logo-name p{
    font-family: 'Belleza', sans-serif;
    font-size: 20px;
    font-weight: 500;
    color: #D7AF4D;
}
.logo-name {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-height: 10vh;
    padding: 0px;
    transition: transform 0.3s ease;
}

.logo-name:hover {
    transform: scale(1.05);
}
@media (min-width: 1400px) {
    .logo-name{
        padding: 0px;
    }
}

@media only screen and (min-width:300px) and (max-width:600px) {
    .logo-name h1{
        font-family: 'Belleza', sans-serif;
        font-size: 18px;
        font-weight: 500;
        color: #D7AF4D;
    }
    .logo-name p{
        font-family: 'Belleza', sans-serif;
        font-size: 14px;
        font-weight: 500;
        color: #D7AF4D;
    }
    .logo-name{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        min-height: 10vh;
        /* padding: 20px 0px 0px 20px; */
    }
    
}


.navbar-toggler {
    transition: transform 0.3s ease;
}

.navbar-toggler:hover {
    transform: rotate(90deg);
}

