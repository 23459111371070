.about-page {
  font-family: 'Poppins', sans-serif;
}

.hero-section {
  min-height: 40vh;
  display: flex;
  align-items: center;
}

.hero-title {
  font-size: 3rem;
  font-weight: 700;
  margin-bottom: 1rem;
}

.hero-subtitle {
  font-size: 1.5rem;
  font-weight: 300;
}

.accent-line {
  width: 27%;
  border: 2px solid #1868fd;
  margin-bottom: 1rem;
}

.story-title {
  font-size: 2.5rem;
  font-weight: 700;
  color: #2a2a72;
}

.icon-accent {
  color: #1868fd;
  margin-right: 0.5rem;
}

.section-title {
  font-size: 2.5rem;
  font-weight: 700;
  color: #2a2a72;
}

.service-card {
  background-color: white;
  padding: 1.5rem;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.service-card:hover {
  transform: translateY(-5px);
}

.logos-slider {
  overflow: hidden;
}

.overflow {
  display: flex;
  padding: 0.5rem 0;
}

.logos-wrapper {
  display: flex;
  animation: logoLoop 30s linear infinite;
}

.logo-item {
  margin: 0 1rem;
  padding: 1rem;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.logo-item img {
  height: 4rem;
  width: auto;
  object-fit: contain;
}

@keyframes logoLoop {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

@media (max-width: 768px) {
  .hero-title {
    font-size: 2rem;
  }
  
  .hero-subtitle {
    font-size: 1.2rem;
  }
  
  .story-title, .section-title {
    font-size: 2rem;
  }
}
