:root {
  --primary-color: #0085ff;
  --secondary-color: #f8f9fa;
  --text-color: #333;
  --transition-speed: 0.1s;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Poppins', sans-serif;
  line-height: 1.6;
  color: var(--text-color);
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
}

/* Hero Section */
.hero {
  padding: 100px 0;
}

.hero-content {
  margin-bottom: 30px;
}

.hero-title {
  font-size: 3rem;
  font-weight: 700;
  margin-bottom: 20px;
  color: var(--primary-color);
}

.hero-description {
  font-size: 1.2rem;
  margin-bottom: 30px;
}

.hero-image img {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  transition: transform var(--transition-speed) ease;
}

.hero-image:hover img {
  transform: scale(1.05);
}

/* Services Section */
.services {
  padding: 80px 0;
  background-color: #ffffff;
}

.section-title {
  font-size: 2.5rem;
  font-weight: 600;
  margin-bottom: 50px;
  color: var(--primary-color);
  text-align: center;
  position: relative;
}

.section-title::after {
  content: '';
  display: block;
  width: 50px;
  height: 3px;
  background-color: var(--primary-color);
  margin: 20px auto 0;
}

.service-card {
  background-color: var(--secondary-color);
  border-radius: 10px;
  padding: 30px;
  height: 100%;
  transition: all var(--transition-speed) ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.service-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
}

.service-icon {
  font-size: 2.5rem;
  color: var(--primary-color);
  margin-bottom: 15px;
}

.service-title {
  font-size: 1.3rem;
  font-weight: 600;
  color: var(--primary-color);
  margin-bottom: 10px;
}

.service-description {
  font-size: 0.9rem;
  color: var(--text-color);
}

.product-card {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.product-image-container {
  height: 250px;
  overflow: hidden;
  background-color: white;
  padding: 20px;
}

.product-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.product-card:hover .product-image {
  transform: scale(1.05);
}

.product-info {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.product-title {
  font-size: 1.1rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.product-dimensions {
  font-size: 0.9rem;
  color: #6c757d;
  margin-bottom: 1rem;
}

.product-actions {
  margin-top: auto;
}

.product-icon {
  color: var(--primary-color);
  font-size: 1.5rem;
}

/* CTA Section */
.cta-section {
  padding: 100px 0;
  color: #ffffff;
}

.cta-section h2 {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.cta-section p {
  font-size: 1.2rem;
  margin-bottom: 30px;
}

/* Buttons */
.btn {
  display: inline-block;
  padding: 12px 30px;
  font-size: 1rem;
  font-weight: 500;
  text-decoration: none;
  border-radius: 5px;
  transition: all var(--transition-speed) ease;
}

.ctc-btn {
  background-color: var(--primary-color);
  color: #ffffff;
}

.ctc-btn:hover {
  background-color: #0066cc;
  transform: translateY(-3px);
  color: #ffffff;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

.ctf-btn {
  background-color: #ffffff;
  color: var(--primary-color);
}

.ctf-btn:hover {
  background-color: #f1f5fd;
  color: var(--primary-color);
  transform: translateY(-3px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

/* Responsive Design */
@media (max-width: 768px) {
  .hero-title {
    font-size: 2.5rem;
  }

  .hero-description {
    font-size: 1rem;
  }

  .section-title {
    font-size: 2rem;
  }

  .service-card,
  .product-card {
    margin-bottom: 30px;
  }

  .cta-section h2 {
    font-size: 2rem;
  }

  .cta-section p {
    font-size: 1rem;
  }
}

@media (max-width: 576px) {
  .hero {
    padding: 60px 0;
  }

  .hero-title {
    font-size: 2rem;
  }

  .section-title {
    font-size: 1.8rem;
  }

  .cta-section {
    padding: 60px 0;
  }

  .cta-section h2 {
    font-size: 1.8rem;
  }

  .btn {
    padding: 10px 20px;
    font-size: 0.9rem;
  }
}
