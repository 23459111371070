
.footer-section{
    background-color: #333;
    /* margin: 50px 0px; */
    /* min-height: 50vh; */
}


.footer-logo{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 20px 0px;
    /* min-height: 50vh; */
}

.footer-address{
    display: flex;
    align-items:flex-start;
    justify-content: center;
    /* min-height: 50vh; */
    flex-direction: column;
    margin: 20px 0px;
}

.footer-address h5{
    color: #f3f3f3;
    font-size: 18px;
    font-weight: 400;
    text-align: left;
}

.footer-address address{
    color: #a4a4a4;
}

.footer-address address a{
    color: #a4a4a4;
    text-decoration: none;
}

.logo-footer{
    display: flex;
    align-items:center;
    justify-content: center;
    /* min-height: 10vh; */
    flex-direction: row;
   
}

.footerlogo-bg{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #0085f0;
    display: flex;
    align-items: center;
    justify-content: center;
   
}
.footer-icon{
    padding: 10px;
}

.footerlogo-bg  a svg{
    fill: #fff;
}

.footerlogo-bg:hover{
    transition: all .3s;
    transform: translateY(-10px);
}

.last-footer-bg{
    background-color: #000;
    min-height: 5vh;
}

.last-footer{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.copy{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
}

.copy svg{
    fill: #fff;
}


.copy p{
    color: #f3f3f3;
    transform: translateY(7px);
}


.dev-team{
    display: flex;
    align-items: center;
    justify-content: center;
}
.dev-team p{
    /* color:#fff; */
    /* transform: translateY(7px); */
}

.dev-team a{
    /* color: #f3f3f3; */
    text-decoration: none;
}
.de {
    color: #fff;
    font-size: xx-small;
  }